<template>
	<div
		class="u-media-library-slider__wrapper u-content-width--full"
		:class="[withBackground && 'theme-light-grey u-death-notices-slider__wrapper--with-background']"
	>
		<h3 class="u-section__title text-uppercase">Traueranzeigen {{ deathNoticeCityName }}</h3>
		<div ref="sliderRef" class="u-media-library-slider splide">
			<div class="splide__track p-2">
				<ul class="splide__list">
					<li class="u-death-notices-slider__slide splide__slide" v-for="(item, index) in deathNoticesData" :key="index">
						<a :href="item.url" target="_blank" class="u-death-notices-slider__item">
							<AtomsBaseImage
								class="u-death-notices-slider__item-image"
								:image="item.teaserImage"
								:alt="item.altText"
							></AtomsBaseImage>
						</a>
					</li>
				</ul>
			</div>
			<div class="u-media-library-slider__arrows splide__arrows">
				<AtomsBaseCTA class="splide__arrow splide__arrow--prev u-death-notices-slider__button" version="primary" title="Zurück">
					<AtomsIconChevron direction="left"></AtomsIconChevron>
				</AtomsBaseCTA>
				<AtomsBaseCTA
					class="splide__arrow splide__arrow--next u-death-notices-slider__button"
					style="margin-top: 150px"
					version="primary"
					title="Weiter"
				>
					<AtomsIconChevron direction="right"></AtomsIconChevron>
				</AtomsBaseCTA>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { onMounted, ref } from "vue";
	import AtomsBaseImage from "../atoms/AtomsBaseImage.vue";
	import AtomsBaseCTA from "../atoms/AtomsBaseCTA.vue";
	import AtomsIconChevron from "../atoms/AtomsIconChevron.vue";
	import Splide from "@splidejs/splide";
	import type { ITeaserProps } from "../molecules/MoleculesTeaser.vue";
	export interface IDeathNoticesSliderProps {
		deathNoticesData: Array<ITeaserProps>;
		withBackground?: boolean;
		deathNoticeCityName?: string;
	}
	withDefaults(defineProps<IDeathNoticesSliderProps>(), {
		withBackground: false,
		deathNoticeCityName: "",
	});
	const sliderRef = ref(null);
	onMounted(() => {
		new Splide(sliderRef.value, {
			pagination: false,
			mediaQuery: "min",
			perPage: 1,
			perMove: 1,
			gap: "2rem",
			breakpoints: {
				619: {
					perPage: 2,
				},
				767: {
					perPage: 3,
				},
				992: {
					perPage: 4,
				},
			},
		}).mount();
	});
</script>
<style lang="scss">
	@import "../../assets/css/mixins";
	.text-uppercase {
		text-transform: uppercase !important;
	}
	.u-media-library-slider__wrapper {
		padding-bottom: 60px !important;
	}
	.u-death-notices-slider__button {
		margin-top: 150px;
	}
	.u-death-notices-slider__wrapper--with-background {
		padding: var(--space-4);
		@include tablet-above {
			padding: var(--space-4) var(--space-7) var(--space-7);
		}
	}
	.u-death-notices-slider__slide {
		padding-top: 1.5rem;
	}
	.u-death-notices-slider__item {
		position: relative;
		display: grid;
		align-items: flex-end;
		text-decoration: none;
	}
	.u-death-notices-slider__item-image {
		grid-column: 1;
		grid-row: 1;
	}
	.u-death-notices-slider__item-image::after {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
</style>

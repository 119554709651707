<script lang="ts" setup>
	import useViewport from "~/composables/useViewport";

	useHead({
		script: [
			{
				src: "//script.ioam.de/iam.js?m=1",
				async: true,
				onload(e) {
					const iam_data = { st: mandant.value, cp: contentType.value, sv: "ke", co: viewport, sc: "yes" };
					if (window.iom && window.iom.c) {
						iom.c(iam_data, 1);
					} else {
						console.error("window.iom not found!");
					}
				},
			},
		],
	});

	const mandantMap: any = {
		swp: "swpresse",
		lr: "lronl",
		moz: "moz",
	};

	const route = useRoute();
	const isHomepage = route.path === "/";
	const mandant = ref<string>(mandantMap[usePublication()]);
	const contentType = ref<string>("nachrichten");
	const viewport = useViewport().toUpperCase() === "MOBILE" ? "MOBILE" : "DESKTOP";

	if (isHomepage) contentType.value = "homepage";
</script>

<template>
	<div>
		<main class="u-main-content content-col" v-if="isArticle">
			<ArticleBody :systemdata="systemdata" :content="adaptedContent" :metadata="metadata"></ArticleBody>
			<ArticleNewsletter v-if="!metadata?.hasNewsletterSignup" :ressorts="ressorts" :audience="audience"></ArticleNewsletter>
			<CleverPushBanner
				v-if="firstRessort && !isApp"
				:id="firstRessort?.id"
				:title="firstRessort?.title"
				:text="firstRessort?.text"
			/>
			<OutBrain adId="recommendation" type="recommendation" />
			<MoleculesAdSlot v-if="showAds.getShowAds() && !isApp" ad_id="billboard2" ad_unit_small="m_sticky"
			                 ad_unit_large="d_billboard2" />
		</main>
		<main class="u-main-content u-main-content--full-content-width" v-if="isPage">
			<div class="u-main-content__inner content-col">
				<MoleculesPlaceableComponents
					:content="adaptedContent"
					:availableComponents="availableComponents"
					:debug="useIsDebug()"
				></MoleculesPlaceableComponents>

				<!-- <Weekli title="Magazine & Prospekte" region="ulm" /> -->
			</div>
			<DeathNotices v-if="displayDeathNotices"></DeathNotices>
			<Bluum v-if="displayBluum"></Bluum>
		</main>

		<ClientOnly>
			<AdScript v-if="showAds.getShowAds()" :metadata="metadata" :systemdata="systemdata" :category-data="categoryData" />
			<IVW />
		</ClientOnly>
	</div>
	<img :src="vgwortUrl" alt="" width="1" height="1" v-if="isArticle && !paywall" />
</template>

<script lang="ts" setup>
	import { ArticleContentTypes, PageType } from "@living-online/types";
	import { useTrackingStore } from "~/stores/tracking.store";
	import { useShowAds } from "~/composables/useShowAds";
	import { usePageStore } from "~/stores/page.store";

	const pageStore = usePageStore();
	// resolveComponent works only in setup not in composables or external helper functions
	const availableComponents = {
		outbrain: { component: resolveComponent("OutBrain") },
		personalizations: { component: resolveComponent("Personalizations") },
		"ad-placeholder": { component: resolveComponent("AdPlaceholder") },
		"newsletter-signup-swp": { component: resolveComponent("Newsletter") },
		"newsletter-signup-lr": { component: resolveComponent("Newsletter") },
		"newsletter-signup-moz": { component: resolveComponent("Newsletter") },
		"global-newsletter-signup": { component: resolveComponent("Newsletter") },
		iframe: { component: resolveComponent("OembedWrapper") },
		oembed: { component: resolveComponent("OembedWrapper") },
		artikelliste: { component: resolveComponent("ArticleList") },
		"newsletter-signup": { component: resolveComponent("Newsletter") },
	};

	const type = pageStore.getType;
	const isArticle = type === PageType.ARTICLE;
	const isPage = type === PageType.PAGE;
	const freemiumState = pageStore.getFreemiumState;
	const systemdata = pageStore.getSystemdata;
	const paywall = pageStore.getPaywall;
	const content = pageStore.getContent;
	const metadata = pageStore.getMetadata;
	const url = pageStore.getUrl;
	const canonical = pageStore.getCanonical;
	const categoryData = pageStore.getCategoryData;
	const { audience, ressorts } = useMetadata(metadata);
	const trackingStore = useTrackingStore();

	const showAds = await useShowAds();

	trackingStore.pageMetaData = metadata;
	trackingStore.pageSystemData = systemdata;
	trackingStore.pagePaywall = paywall;
	const adaptedContent = useTrackComponents(content);
	const displayBluum = useBluum(content);
	const isApp = useIsApp();

	const config = useClientSideConfig();
	const cleverPushIdMapping = config.cleverpush_widget_mapping;
	const firstRessortId = metadata?.["ressorts-" + usePublication()]?.references[0]?.id;
	const firstRessort = cleverPushIdMapping[firstRessortId];

	const articleId = systemdata?.documentId;
	const vgwortUrl = buildVGWortUrl(articleId, freemiumState);

	const displayDeathNotices = useDeathNotices(content);
	//Redirect if necessary
	if (url !== undefined && useRoute().path !== url) {
		let query = Object.entries(useRoute().query)
			.map(([key, value]) => `${key}=${value}`)
			.join("&");

		if (query.length > 0) {
			if (url.indexOf("?") !== -1 && !url.endsWith("?")) {
				query = "&" + query;
			} else if (url.indexOf("?") === -1) {
				query = "?" + query;
			}
		}

		navigateTo(url + query, {
			external: !url.startsWith("/"),
			redirectCode: 301,
		});
	}

	if (systemdata && metadata) {
		function getTitle() {
			if (ArticleContentTypes.includes(systemdata.contentType)) {
				// article
				const headContent = content?.find((c) => c.component === "head")?.content || {};
				return (
					metadata?.seoTitle ||
					[metadata?.overline, metadata?.title]
						.map((s) => (typeof s === "string" ? s.trim() : ""))
						.filter((s) => s.length > 0)
						.join(": ") ||
					metadata?.teaserTitle
				);
			} else {
				// page
				return metadata.title || metadata.seoTitle;
			}
		}
		const title = getTitle();
		function getImage() {
			if (ArticleContentTypes.includes(systemdata.contentType)) {
				// article
				const image = metadata?.teaserImage?.crops?.find((c: any) => c.name === "16:9")?.url;

				return image;
			} else {
				// page
				return undefined;
			}
		}

		function getRobots(url: string) {
			const publication = usePublication();
			const canonicalPublication = usePublication(url);

			return (
				(publication !== canonicalPublication ? "noindex, nofollow, noarchive" : metadata?.robots) +
				", max-snippet:-1, max-image-preview:large, max-video-preview:-1"
			);
		}

		useBaseMetaHead(title, {
			description: metadata?.seoTeaser || metadata?.description,
			image: getImage(),
			robots: getRobots(canonical),
			seoTitle: metadata?.seoTitle || metadata?.teaserTitle,
			canonical,
		});
		useHead({
			meta:
				metadata.retresco?.entities?.map((e: any) => {
					return { name: "article:tag", content: e.name };
				}) || [],
		});

		// get the breadcrumb
		if (useRoute().path !== "/") {
			const response = await useFetch("/api/page/breadcrumb", {
				method: "POST",
				body: {
					path: useRoute().path,
				},
			});

			const c = response?.data?.value;

			await useBreadcrumb([
				...(c || []),
				{
					label: title,
				},
			]);
		} else {
			await useBreadcrumb([]);
		}
	}

	function buildVGWortUrl(articleId: Number, freemiumState: String) {
		if (!config.vgwort) {
			console.error("VGWort not Found!");
			return;
		}

		if (!config.vgwort.url || !config.vgwort.verlagsnr) {
			console.error("VGWort not Configured!");
			return;
		}

		let paywallMarker = "";
		if (freemiumState === "plus") {
			paywallMarker = "pw-";
		}

		const vgwortUrl = `${config.vgwort.url}${paywallMarker}vgzm.${config.vgwort.verlagsnr}-${articleId}`;
		return vgwortUrl;
	}
</script>

<style lang="scss">
	.u-abo {
		&-header {
			margin-inline: auto;
			max-width: var(--max-content-width);
		}

		&-link__wrapper {
			margin-inline: auto;
			max-width: var(--max-content-width);
		}
	}
	// Blumm Placeholder for Teleport
	.bluum__ph {
		display: none;
	}
	.deathNoticesWidgetPlaceholder {
		display: none;
	}
</style>

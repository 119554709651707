<template>
	<article class="u-article font-set-4">
		<MoleculesPlaceableComponents
			:content="content"
			:availableComponents="availableComponents"
			:debug="useIsDebug()"
		></MoleculesPlaceableComponents>
	</article>
</template>

<script lang="ts" setup>
	withDefaults(
		defineProps<{
			content?: Array<IPlaceableComponentProps>;
		}>(),
		{},
	);

	// resolveComponent works only in setup not in composables or external helper functions
	const availableComponents = {
		outbrain: { component: resolveComponent("OutBrain") },
		personalizations: { component: resolveComponent("Personalizations") },
		"ad-placeholder": { component: resolveComponent("AdPlaceholder") },
		"newsletter-signup-swp": { component: resolveComponent("Newsletter") },
		"newsletter-signup-lr": { component: resolveComponent("Newsletter") },
		"newsletter-signup-moz": { component: resolveComponent("Newsletter") },
		"global-newsletter-signup": { component: resolveComponent("Newsletter") },
		"newsletter-signup": { component: resolveComponent("Newsletter") },
		iframe: { component: resolveComponent("OembedWrapper") },
		oembed: { component: resolveComponent("OembedWrapper") },
		artikelliste: { component: resolveComponent("ArticleList") },
	};
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-article {
		> ul {
			padding-left: 1.25rem;
		}

		p a {
			font-weight: 700;
		}
	}
</style>

<template>
	<Teleport to="#bluum">
		<div class="jobiqoBanner" id="jobiqoBanner">
			<div class="jobiqoBanner__left">
				<svg
					id="jobiqoBanner-image-left"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					version="1.1"
					x="0px"
					y="0px"
					viewBox="0 0 400 109.92"
					style="enable-background: new 0 0 400 109.92"
					xml:space="preserve"
				>
					<g>
						<path
							style="fill: #02065b"
							d="M65.5,76.2c0,18.74-14.29,33.72-33.03,33.72S0,95.17,0,75.63v-56.7h13.14v35.32   c4.8-6.29,12-10.4,21.49-10.4c17.72,0,30.86,14.29,30.86,32.35H65.5z M52.24,76.89c0-11.89-8-21.03-19.55-21.03   s-20.12,9.26-20.12,21.26s8.34,20.92,20.12,20.92S52.24,88.67,52.24,76.89z"
						></path>
						<path
							style="fill: #02065b"
							d="M76.03,18.93h13.72v71.55c0,4.8,3.08,6.74,6.17,6.74c1.94,0,3.43-0.46,4.69-1.14v11.77   c-1.94,1.37-5.26,2.06-8.46,2.06c-10.29,0-16.12-7.43-16.12-17.6V18.93z"
						></path>
						<path
							style="fill: #02065b"
							d="M109.87,78.71V45.56h13.72v34.06c0,10.86,5.14,18.17,16.35,18.17s16.35-7.2,16.35-18.17V45.56h13.72   v33.26c0,19.2-10.63,31.09-30.06,31.09s-30.06-12-30.06-31.21L109.87,78.71z"
						></path>
						<path
							style="fill: #02065b"
							d="M180.78,78.71V45.56h13.72v34.06c0,10.86,5.14,18.17,16.35,18.17s16.35-7.2,16.35-18.17V45.56h13.72   v33.26c0,19.2-10.63,31.09-30.06,31.09s-30.06-12-30.06-31.21L180.78,78.71z"
						></path>
						<path
							style="fill: #02065b"
							d="M356.61,69.91v38.18h-13.72V71.97c0-10.52-5.15-16.57-14.06-16.57c-10.06,0-16.69,7.66-16.69,18.97   v33.72h-13.72V71.97c0-10.4-5.26-16.57-14.17-16.57c-10.06,0-16.69,7.66-16.69,18.97v33.72h-13.72V45.57h13.03v9.37   c2.97-6.06,10.4-11.09,19.77-11.09c10.52,0,18.52,4.69,22.52,12.57c3.54-6.74,11.54-12.57,22.06-12.57   c15.43,0,25.38,10.06,25.38,26.06H356.61z"
						></path>
						<path
							style="fill: #02065b"
							d="M339.52,0c-4.48,0-4.48,9.45-4.48,9.45v9.45c0,0,0,9.45,4.48,9.45S344,18.9,344,18.9V9.45   C344,9.45,344,0,339.52,0z"
						></path>
						<path
							style="fill: #02065b"
							d="M304.11,12.76c-3.64,2.64,1.42,9.61,1.42,9.61l5.06,6.96c0,0,5.06,6.96,8.7,4.32   s-1.42-9.61-1.42-9.61l-5.06-6.96C312.81,17.08,307.75,10.12,304.11,12.76z"
						></path>
						<path
							style="fill: #02065b"
							d="M397.44,44.69c-1.36-4.18-10.4-1.24-10.4-1.24L378,46.39c0,0-9.04,2.94-7.68,7.12   c1.36,4.18,10.4,1.24,10.4,1.24l9.04-2.94C389.76,51.81,398.8,48.87,397.44,44.69z"
						></path>
						<path
							style="fill: #02065b"
							d="M374.92,14.57c-3.85-2.8-9.07,4.39-9.07,4.39l-5.22,7.19c0,0-5.22,7.19-1.37,9.98   c3.85,2.8,9.07-4.39,9.07-4.39l5.22-7.19C373.55,24.55,378.77,17.36,374.92,14.57z"
						></path>
						<path
							style="fill: #02065b"
							d="M399.86,80.52c1.33-4.1-7.31-6.9-7.31-6.9l-8.64-2.81c0,0-8.64-2.81-9.97,1.29s7.31,6.9,7.31,6.9   l8.64,2.81C389.89,81.81,398.53,84.62,399.86,80.52z"
						></path>
					</g>
				</svg>
			</div>
			<div class="jobiqoBanner__center">
				<p class="jobiqoBanner__text">job dich neu <br class="jobiqoBanner__textbreak" />{{ bluumBannerState }}</p>
				<p class="jobiqoBanner__anchor">
					Jetzt auf
					<AtomsBaseLink
						:url="bluumButtonLink"
						:external="true"
						:label="bluumBannerLinkLabel"
						class="u-base-cta u-base-cta--version-link jobiqoBanner__anchor"
					></AtomsBaseLink>
				</p>
			</div>
			<div class="jobiqoBanner__right">
				<img id="jobiqoBanner-image-right" src="/static/images/bluum_banner_right.png" />
			</div>
		</div>
		<div id="jobiqo_job_widget"></div>
		<div class="jobiqoAction">
			<AtomsBaseLink :url="bluumButtonLink" :external="true" class="u-base-cta u-base-cta--version-link jobiqo-link"
				>Mehr Jobs</AtomsBaseLink
			>
		</div>
	</Teleport>
</template>

<script setup lang="ts">
	import { useScriptTag } from "@vueuse/core";

	const bluumButtonLink = usePublication() === "swp" ? "https://bw.bluum.de/" : "https://bb.bluum.de/";
	const bluumBannerLinkLabel = usePublication() === "swp" ? "bw.bluum.de" : "bb.bluum.de";
	const bluumBannerState = usePublication() === "swp" ? "baden-württemberg" : "brandenburg";

	function executeJobiqoJobWidget() {
		const root = document.getElementById("bluum");
		const geoData = root?.dataset?.bluumGeodata ? JSON.parse(root.dataset.bluumGeodata) : false;
		const campaign = root?.dataset?.bluumCampaign ? root.dataset.bluumCampaign : "launch";
		const tenant = location.hostname.includes("swp") ? { subDomain: "bw", frontend: "npg" } : { subDomain: "bb", frontend: "npg2" };

		const jobiqoParams = {
			endpointUrl: "https://backend.bluum.de/graphql",
			autocompleteUrl: "http://backend.bluum.de/search_api_autocomplete",
			baseUrl: `https://${tenant.subDomain}.bluum.de`,
			jobAmount: 6,
			showSearchBar: false,
			frontend: `frontend=${tenant.frontend};locale=de`,
			radius: {
				options: [5, 25, 50, 100, 200],
				unit: "km",
			},
			showSearchResult: true,
			googleApiKey: "",
			utmParameters: {
				source: `${tenant.subDomain}.bluum.de`,
				medium: "jobwidget",
				campaign: `${campaign}`,
			},
			styles: {
				fontFamily: '"Mulish",Sans-serif',
				titleColor: "#1a3eff",
				locationColor: "#000000",
				occupationColor: "#000000",
				titleFontSize: "1rem",
				locationFontSize: "1rem",
				occupationFontSize: "0.9rem",
				hideLogos: true,
				hideOrganizationNames: false,
				hideOccupations: true,
				linkColor: "#1a3eff",
			},
			...(geoData && {
				filters: {
					where: { lat: parseFloat(geoData?.lat), lon: parseFloat(geoData?.lon), radius: parseFloat(geoData?.radius) },
				},
			}),
		};

		jobiqoJobWidget.init(jobiqoParams);
	}

	// The script will be automatically loaded when the component is mounted and removed when the component is unmounted.
	useScriptTag("https://bw.bluum.de/job_widget/jobiqo_job_widget.js", () => executeJobiqoJobWidget());
</script>

<style lang="scss">
	@font-face {
		font-display: swap;
		font-family: "Mulish";
		font-style: normal;
		font-weight: 200 1000;
		src:
			local(""),
			url("/static/fonts/mulish-v13-latin-variable.woff2") format("woff2");
	}

	.jobiqoBanner {
		--Color-bluum-primary: #07e996;
		--Color-bluum-secondary: #03055c;
		--Color-bluum-ternary: #1a3eff;
		--Text-textColor: var(--Color-bluum-primary);
		display: flex;
		flex-wrap: wrap;
		font-family: "Mulish", sans-serif;
		background-color: var(--Color-bluum-primary, #07e996);
		color: var(--Color-bluum-secondary, #03055c);
		position: relative;
		overflow-x: hidden;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	@media (min-width: 768px) {
		.jobiqoBanner {
			padding-top: 0;
			padding-bottom: 0;
			align-items: center;
		}
	}

	.jobiqoBanner__left {
		position: relative;
		width: 100%;
		padding-right: 0;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
		padding-left: 1.5rem;
	}

	@media (min-width: 768px) {
		.jobiqoBanner__left {
			margin-right: 4rem;
		}
	}

	.jobiqoBanner__center {
		position: relative;
		width: 100%;
		padding-right: 0;
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 1.5rem;
	}

	@media (min-width: 768px) {
		.jobiqoBanner__center {
			flex: 0 0 auto;
			width: auto;
			max-width: 100%;
			padding-left: 0;
		}
	}

	.jobiqoBanner__right {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
	}

	@media (min-width: 768px) {
		.jobiqoBanner__right {
			position: inherit;
			bottom: unset;
			right: unset;
			margin-left: auto;
		}
	}

	.jobiqoBanner__text {
		font-weight: 700;
		line-height: 1.5rem;
		margin-bottom: 0;
		margin-top: 0;
	}

	.jobiqoBanner__textbreak {
		display: none;
	}

	@media (min-width: 576px) and (max-width: 991.98px) {
		.jobiqoBanner__textbreak {
			display: block;
		}
	}

	.jobiqoBanner__anchor {
		font-weight: 400;
		color: var(--Color-bluum-ternary);
		line-height: 1.5rem;
		margin-bottom: 0;
		margin-top: 0;
		min-height: 0;
	}

	.jobiqoBanner__anchor a {
		text-decoration: underline;
	}

	.jobiqoBanner__anchor a:hover,
	.jobiqoBanner__anchor a:visited {
		color: var(--Color-bluum-ternary);
	}

	#jobiqoBanner-image-left {
		width: 163px;
	}

	@media (min-width: 768px) {
		#jobiqoBanner-image-left {
			width: 231px;
		}
	}

	#jobiqoBanner-image-right {
		display: block;
		transform: translate(calc(50% - 2rem));
	}

	@media (min-width: 992px) {
		#jobiqoBanner-image-right {
			transform: translate(0);
		}
	}

	.jobiqoAction {
		display: flex;
		justify-content: center;
	}

	.jobiqo-link {
		font-family: "Mulish", "Roboto", sans-serif;
		background-color: #07e996;
		color: #03055c;
		margin-top: 1.5rem;
		padding: var(--button-padding, var(--space-2));
		text-decoration: none;
	}

	.jobiqo-link:hover {
		background-color: #07e996 !important;
		color: #03055c !important;
		text-decoration: underline;
	}

	@media (min-width: 992px) {
		#jobiqo_job_widget > .App > div {
			display: grid;
			gap: 1rem;
			grid-template-columns: 1fr 1fr;
		}
	}

	#jobiqo_job_widget > .App > div .content > div:first-child {
		font-weight: 700;
	}

	#jobiqo_job_widget .lyYpzL03su5BwIQIyZ1e {
		display: flex;
		font-size: 0.9rem;
	}
	#jobiqo_job_widget svg {
		width: 1em;
		height: 1em;
	}
</style>

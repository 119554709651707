<template>
	<ClientOnly>
		<Teleport to="#deathNoticesWidget">
			<OrganismsDeathNoticesSlider :withBackground="true" :deathNoticeCityName="deathNoticeCityName" :deathNoticesData="deathNoticesData"></OrganismsDeathNoticesSlider>
		</Teleport>
	</ClientOnly>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import OrganismsDeathNoticesSlider from "../../storybook/src/components/organisms/OrganismsDeathNoticesSlider.vue";
const deathNoticesData: Ref<{}[]> = ref([]);
const deathNoticeCityName: Ref<string> = ref("");
const deathNoticesWidget: HTMLElement | null = document?.getElementById("deathNoticesWidget");
if (typeof deathNoticesWidget === "object") {
	const deathNoticesTenant = deathNoticesWidget?.dataset?.deathNoticesTenant ? deathNoticesWidget.dataset.deathNoticesTenant : false;
	deathNoticeCityName.value = deathNoticesWidget?.dataset?.deathNoticesCityname ? deathNoticesWidget.dataset.deathNoticesCityname : "";
	try {
		const deathNoticesResponse = await $fetch("/api/advertisement/deathnotices", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				tenant: deathNoticesTenant
			}),
		});
		if (deathNoticesResponse.Announcements.Announcement.length == 0 && typeof deathNoticesWidget === "object" && typeof deathNoticesWidget.parentNode !== "undefined") {
			deathNoticesWidget.parentNode.removeChild(deathNoticesWidget);
		} else {
			deathNoticesResponse.Announcements.Announcement.map(function(value:any, key:any){
				const altString = computed(() => {
					return `Traueranzeige ${value.FirstName} ${value.LastName}`;
				});
				let ThumbnailPath = "";
				if (value.Ads.Ad.length > 0) {
					ThumbnailPath = value.Ads.Ad[0].ThumbnailPath;
				} else {
					ThumbnailPath = value.Ads.Ad.ThumbnailPath;
				}
				deathNoticesData.value.push({teaserImage: {url: ThumbnailPath, width: 500, height: 250, altText: altString.value}, url: value.URL, overline: "ABCD", teaserTitle: "BCDE"});
			});
		}
	} catch (error) {
		console.error(error);
	}
}
</script>
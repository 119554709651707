<template>
	<section class="u-newsletter" ref="newsletterFormRef">
		<NewsletterForm v-bind="newsletterFormData" @error="showError" @submit="handleSubmit"></NewsletterForm>
	</section>
</template>

<script setup lang="ts">
	import { ref, reactive } from "vue";
	import NewsletterForm from "~/components/NewsletterForm.vue";

	const url = useRequestURL();
	const isLoggedIn = useUserIsLoggedIn();
	const config = useClientSideConfig();
	const newsletterList: Ref<any[]> = ref([]);
	const userCookieData = useUserDataFromCookie();

	const props = withDefaults(
		defineProps<{
			formTitle?: string;
			formText?: string;
			formButtonLabel?: string;
			formAgreementText?: string;
			formInfoText?: string;
			subscriptionSource?: string;
			userdata?: any;
			tagList?: { title: string; key: string }[];
			newsletterIcon?: string;
			newsletterNotificationTime?: string;
			salescloudKey?: string;
		}>(),
		{
			userdata: {},
			subscriptionSource: "NL-Anmeldung Übersichtsseite",
		},
	);

	const newsletterIconPath = "/static/images/newsletter/";
	const newsletterFormRef = ref();
	const newsletterFormData = reactive({
		countAbos: 0,
		formKey: props.salescloudKey ? props.salescloudKey : "",
		formTitle: props.formTitle,
		formText: props.formText,
		formAgreementText: config?.newsletterAgreementText,
		formInfoText: config?.newsletterTextHint,
		loggedIn: isLoggedIn,
		userData: {
			salutation: props.userdata?.salutation || userCookieData?.salutation,
			firstName: props.userdata?.firstname || userCookieData?.firstname,
			lastName: props.userdata?.lastname || userCookieData?.lastname,
			email: props.userdata?.email || userCookieData?.email,
		},
		tagList: props.tagList,
		response: {},
		newsletterIcon: props.salescloudKey ? newsletterIconPath + props.salescloudKey + ".svg" : "",
		newsletterNotificationTime: props.newsletterNotificationTime,
	});

	const { data: newsletter } = await useFetch("/api/newsletter");
	newsletterList.value = newsletter.value || [];

	async function handleSubmit(userdata: any) {
		if (userdata?.aboList.length) {
			document.body.dispatchEvent(
				new CustomEvent("avo-submit-newsletter-form", {
					detail: newsletterFormRef.value,
				}),
			);
			userdata?.aboList.forEach(async (element: any) => {
				let currentAbo = newsletterList.value.filter((item) => item.salescloud_key === element)[0];

				const body = {
					newsletter_meta_data: config.newsletter_meta_data,
					subscriptionSource: props.subscriptionSource,
					currentURL: url?.href,
					...userdata,
					campaignId: currentAbo.campaign_id,
				};

				const { data, error } = await useFetch("/api/user/newsletter", {
					method: "POST",
					body,
				});

				if (data.value === "") {
					newsletterFormData.response.title = "Ihre Anmeldung war erfolgreich";
					newsletterFormData.response.message =
						"Danke für Ihre Bestellung, Ihre Anmeldung war erfolgreich! In Kürze erhalten Sie eine Aktivierungsmail. Bitte beachten Sie: Wenn Sie Ihre E-Mail-Adresse schon einmal bei uns bestätigt haben, erhalten Sie keine Aktivierungsmail mehr.";
					newsletterFormData.response.status = "success";
				}

				if (error.value !== null) {
					newsletterFormData.response.title = null;
					newsletterFormData.response.message = "Ein Fehler ist aufgetreten versuchen Sie es bitte später erneut.";
					newsletterFormData.response.status = "error";

					throw new Error("Network response was not OK");
				}
			});
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../storybook/src/assets/css/mixins";
	.u-newsletter {
		display: flex;
		max-width: none;

		@include desktop {
			flex-direction: row;
		}
	}
</style>

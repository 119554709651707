<template>
	<ArticleContainer :content="content" class="u-main-content__inner"></ArticleContainer>
</template>

<script lang="ts" setup>
	import type { WithContext, NewsArticle } from "schema-dts";

	const props = withDefaults(
		defineProps<{
			systemdata: any;
			content: any;
			metadata: any;
		}>(),
		{},
	);

	const jsonLdImage = {
		url: useImage.setImageParams(useImage.extractURLFromArticleMetadata(props.metadata), { width: 1200, ar: "16:9", fit: "cover" }),
		width: 1200,
		height: 675,
	};

	const config = useClientSideConfig();
	const publisherLogo = useUrlHelper.getAbsoluteUrl(config.jsonLdPublisherLogo);
	const baseUrl = usePublicationInfo().baseUrl;
	const articleUrl = useRoute().path;

	let jsonLdData: WithContext<NewsArticle> = {
		"@context": "https://schema.org",
		"@type": "NewsArticle",
		isPartOf: {
			"@type": ["CreativeWork", "Product"],
			name: config.jsonLdPageName,
			productID: config.jsonLdProductId,
		} as any,
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": `${baseUrl}${articleUrl}`,
		},
		headline:
			props.metadata?.seoTitle ||
			[props.metadata?.overline, props.metadata?.title]
				.map((s) => (typeof s === "string" ? s.trim() : ""))
				.filter((s) => s.length > 0)
				.join(": ") ||
			props.metadata?.teaserTitle,
		//@ts-ignore
		image: jsonLdImage
			? [
					{
						"@type": "ImageObject",
						url: jsonLdImage.url,
						width: jsonLdImage.width,
						height: jsonLdImage.height,
					},
				]
			: undefined,
		datePublished: props.metadata["jsonld"].datePublished,
		dateModified: props.metadata["jsonld"].dateModified, // muss identisch zu datePublished sein. Vor Änderungen, d.kuehner@swp.de informieren!
		author: await Promise.all(
			(props?.metadata?.authors?.values || []).map(async (author: any) => {
				const authorUrl = `/autor/${author?.metadata?.slug?.trim() || ""}-${author?.systemdata?.documentId || ""}.html`;
				return {
					"@type": "Person",
					name: [author?.metadata?.prename, author?.metadata?.surname].join(" "),
					givenName: author?.metadata?.prename,
					familyName: author?.metadata?.surname,
					url: authorUrl ? `${baseUrl}${authorUrl}` : undefined,
					email: author?.metadata?.email,
					jobTitle: author?.metadata?.profile,
				};
			}),
		),
		publisher: {
			"@type": "Organization",
			name: config.jsonLdPageName,
			url: config.jsonLdPageUrl,
			logo: {
				"@type": "ImageObject",
				url: publisherLogo,
				width: "600",
				height: "60",
			},
			sameAs: config.socialUrls,
		},
		description: props.metadata?.seoTeaser || props.metadata?.description,
		isAccessibleForFree: props.metadata?.payCategory !== "plus",
	};

	// Add paywall JSON
	if (props.metadata?.payCategory === "plus") {
		jsonLdData = {
			...jsonLdData,
			isAccessibleForFree: false,
			hasPart: {
				"@type": "WebPageElement",
				isAccessibleForFree: false,
				cssSelector: ".u-paragraph--paywall-preview",
			},
		};
	}

	useJsonld(() => jsonLdData);
</script>

<style lang="scss"></style>

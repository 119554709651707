/**
 * This util contains helper functions regarding the work with livingdocs-images.
 */

function getRatioMultiplier(ratio: string, invert: boolean = false) {
	if (!/^[0-9]+:[0-9]+$/.test(ratio)) {
		throw new Error("Invalid ratio format. Use 'width:height'");
	}

	const [width, height] = ratio.split(":");
	return invert ? Number(width) / Number(height) : Number(height) / Number(width);
}

export const useImage = {
	/**
	 * Holt sich die Image-URL aus den übergebenen Metadaten.
	 *
	 * @param article
	 */
	extractURLFromArticleMetadata: (article: any): string | undefined => {
		// retrieve the base version of the image from the crops
		let teaserImageUrl = article?.teaserImage?.crops?.[0]?.url;
		// retrieve the base version of the image as root property
		if (!teaserImageUrl && typeof article?.teaserImage === "string") {
			teaserImageUrl = article.teaserImage;
		}
		// retrieve the base version of the image as child property
		if (!teaserImageUrl) {
			teaserImageUrl = article?.teaserImage?.url;
		}
		return teaserImageUrl;
	},

	/**
	 * Uses an image-url and adds rendering parameters. Supports all params that are available in keycdn-API (https://www.keycdn.com/support/image-processing)
	 * There is no check of the given params.
	 * Has support for an 'ar' parameter which calculates the missing width or height based on the aspect ratio. (eg: ar: '16:9' and width: 800 -> width: 800, height: 450)
	 *
	 * @param imageUrl
	 * @param params
	 */
	setImageParams: (imageUrl: string | undefined, params: any) => {
		if (!imageUrl) return undefined;
		try {
			if ("ar" in params && ("width" in params || "height" in params)) {
				params.height = params.height || Math.round(params.width * getRatioMultiplier(params.ar));
				params.width = params.width || Math.round(params.height * getRatioMultiplier(params.ar, true));
				delete params.ar;
			}

			const urlObject = new URL(imageUrl);
			const urlParams = new URLSearchParams(urlObject.search);
			for (const [param, value] of Object.entries(params)) {
				urlParams.set(param, value as string);
			}
			urlObject.search = urlParams.toString();
			return urlObject.href;
		} catch (e: any) {
			console.error(e.toString());
			return undefined;
		}
	},

	/**
	 * Takes an author and extracts the author image. Uses the cropped version, if available.
	 *
	 * @param author
	 */
	getAuthorImage: (author: any) => {
		let authorImage = author?.metadata?.authorImage;
		const crops = authorImage?.crops;
		// Check for cropped images
		if (crops && crops.length > 0) {
			if (crops[0]?.url) {
				authorImage = crops[0];
			}
		}
		if (authorImage && authorImage?.url) {
			return authorImage.url;
		}
		// Neither cropped nor authorImage found
		return undefined;
	},
};

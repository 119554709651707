/**
 * Takes a URL and returns it as an absolute URL.
 * If the URL format is invalid or cannot be calculated, returns undefined.
 *
 * @param url - The URL to be processed
 * @param baseUrl - The base URL to be used for relative URLs
 * @returns an absolute URL or undefined
 */
export const useUrlHelper = {
	getAbsoluteUrl: (url: string, baseUrl?: string): string | undefined => {
		if (!url) {
			return undefined;
		}
		if (!baseUrl) {
			baseUrl = useRequestURL()?.href || window?.location?.href;
		}

		try {
			// Check if the URL is already absolute
			const parsedUrl = new URL(url, baseUrl);
			return parsedUrl.href;
		} catch (error) {
			// If there's an error in URL parsing, return undefined
			console.error("Error while trying to make the URL absolute:", error);
			return undefined;
		}
	},
};
